/**************************************************
 * Nombre:       Dialogo_Producto
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Button, Grid} from "@mui/material";
import {useFormulario} from "../../Modulo_formularios/Hooks/useFormulario";
import Formulario_Productos from "../Formularios/Formulario_Productos";
import {LoadingButton} from "@mui/lab";
import {Delete, Flag, LocalMall} from "@mui/icons-material";
import {subirImagenFile} from "../../Servicios/Storage/subirImagenFile";
import {guardarDoc} from "../../Servicios/BD/guardarDoc";
import {useEffect, useState} from "react";
import {useLoaders} from "../../Modulo_Loaders/Hooks/useLoaders";
import logo from '../../Recursos/logo_redondo.svg'
import {borrarDoc} from "../../Servicios/BD/borrarDoc";
import Formulario_Categoria from "../Formularios/Formulario_Categoria";
import Formulario_Potenciales from "../Formularios/Formulario_Potenciales";

const Dialogo_Potenciales = ({cerrar, realizado, datos}) => {
    const {props, obtenerEntidad} = useFormulario({valoresDefecto: datos})
    const [cargando, setCargando] = useState(false)
    const {Cargador, abrirCargador, cerrarCargador} = useLoaders({
        logo: logo,
    })
    const borrar = () => {
        abrirCargador('Borrando Categoria')
        borrarDoc('sociosPotenciales', datos.id).then((dox) => {
            cerrarCargador()
            realizado()
        })
    }

    return (

        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >

            <Cargador/>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                <Formulario_Potenciales props={props}/>
            </Grid>


            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                {datos && datos.id &&
                    <Button
                        onClick={() => borrar()}
                        variant={'text'}
                        sx={{color: '#00000080', px: 0, marginTop: 2}}
                        startIcon={<Delete/>}
                    >Borrar Potencial</Button>
                }
            </Grid>


        </Grid>

    )

}
export default Dialogo_Potenciales