export const obtenerDimencionTotal = (productos) => {

    let centimetrosCubicos = 0
    for (let i = 0; i < productos.length; i++) {
        let producto = productos[i]
        let volumen = (parseInt(producto.alto) * parseInt(producto.ancho) * parseInt(producto.fondo)) * producto.cantidad
        centimetrosCubicos = centimetrosCubicos + volumen
    }

    let arista = Math.cbrt(centimetrosCubicos)
    arista = arista + 1

    let dimencionTotal = {
        alto: parseInt(arista.toFixed(0)),
        ancho: parseInt(arista.toFixed(0)),
        fondo: parseInt(arista.toFixed(0))
    }

    return dimencionTotal

}