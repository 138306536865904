/**************************************************
 * Nombre:       SeleccionadorOpciones
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid, Radio, RadioGroup} from "@mui/material";
import Tarjeta_Empresa_Envio from "../Tarjetas/Tarjeta_Empresa_Envio";

const SeleccionadorOpciones = ({opciones = [], idCotizacion, setIdCotizacion}) => {


    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            sx={{pl: 1}}
        >

            <RadioGroup
                onChange={(e) => setIdCotizacion(e.target.value)}
                value={idCotizacion}
            >
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="flex-start"
                    spacing={2}
                    sx={{px: 1}}
                >

                    {opciones.map((item, index) => {
                        return (
                            <Grid key={`wsq${index}`} item container lg={12} sm={12} xs={12}
                                  sx={{justifyContent: 'flex-start'}}>
                                <Tarjeta_Empresa_Envio item={item}/>
                            </Grid>
                        )
                    })}

                </Grid>
            </RadioGroup>


        </Grid>
    )

}
export default SeleccionadorOpciones