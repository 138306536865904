import {DIVIPOLA} from "../Constantes";

export const obtenerCiudades = (departamento) => {

    let arr = DIVIPOLA.map((it) => {

        if (it.NOMBRE_DEPTO === departamento) {
            return it.Nombre
        }
    })

    let set = new Set(arr)

    let result = [...set]

    return result.sort()

}