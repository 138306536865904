/**************************************************
 * Nombre:       Tarjeta_Empresa_Envio
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid, Radio, Typography, useMediaQuery} from "@mui/material";
import cordi from '../Recursos/coordinadora.svg'
import envia from '../Recursos/envia.svg'
import tcc from '../Recursos/tcc.svg'
import menurb from '../Recursos/menurb.svg'
import servi from '../Recursos/servientrega.svg'
import {formatoMoneda} from "../../Utilidades/formatoMoneda";
import {theme} from "../../Tema";

const Tarjeta_Empresa_Envio = ({item}) => {
    const sCell = useMediaQuery(theme.breakpoints.only('xs'))

    let obtenerImagen = (idCarrier) => {

        switch (idCarrier) {
            case 14:
                //servientrega
                return cordi
                break

            case 23:
                //servientrega
                return servi
                break
            case 28:
                //envia
                return envia
                break
            case 29:
                //cordinadora
                return cordi
                break
            case 40:
                //servientrega
                return menurb
                break
            case 44:
                //servientrega
                return tcc
                break

            default:
                return cordi
        }
    }

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            sx={{border: 1, borderRadius: 2, borderColor: '#00000050'}}
        >

            <Grid item container lg={2} sm={2} xs={2} sx={{justifyContent: 'flex-start'}}>
                <img src={obtenerImagen(item.idCarrier)} width={sCell ? 55 : 60} height={'auto'}
                     style={{borderTopLeftRadius: 6, borderBottomLeftRadius: 6}}/>
            </Grid>

            <Grid item container lg={5} sm={5} xs={5} sx={{justifyContent: 'flex-start'}}>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="flex-start"
                    sx={{pl: sCell ? 2 : 1}}
                >
                    <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                        <Typography sx={{fontWeight: 600, fontSize: sCell ? 14 : 16}}>{item.carrier}</Typography>
                    </Grid>

                    <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                        <Typography sx={{fontWeight: 600, fontSize: sCell ? 10 : 12, color: '#00000090'}}>Tiempo: <span
                            style={{
                                paddingRight: 10,
                                fontWeight: 500
                            }}>{`${item.deliveryDays} dias`}</span></Typography>
                    </Grid>

                    <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: -0.5}}>
                        <Typography sx={{fontWeight: 600, fontSize: sCell ? 10 : 12, color: '#00000090'}}>Tipo: <span
                            style={{paddingRight: 10, fontWeight: 500}}>{item.product}</span></Typography>
                    </Grid>


                </Grid>
            </Grid>

            <Grid item container lg={3} sm={3} xs={3} sx={{justifyContent: 'flex-end'}}>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="flex-start"
                >

                    <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-end'}}>
                        <Typography sx={{color: '#00000090', fontSize: sCell ? 12 : 14}}>Precio</Typography>
                    </Grid>

                    <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-end', marginTop: -0.5}}>
                        <Typography
                            sx={{fontWeight: 600, fontSize: sCell ? 14 : 16}}>{formatoMoneda(item.flete)}</Typography>
                    </Grid>


                </Grid>
            </Grid>

            <Grid item container lg={2} sm={2} xs={2} sx={{justifyContent: 'center'}}>

                <Radio value={item.idRate} sx={{justifyContent: 'flex-end', pl: 3}}/>

            </Grid>

        </Grid>
    )

}
export default Tarjeta_Empresa_Envio    