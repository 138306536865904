import {DIVIPOLA} from "../Constantes";

export const obtenerDaneCode = (departamento, ciudad) => {

    let esto = DIVIPOLA.filter((e) => e.NOMBRE_DEPTO === departamento && e.Nombre === ciudad)

    if (esto.length > 0) {
        return esto[0].CODIGO_MUNICIPIO
    } else {
        return 0
    }

}