export const adaptador_indicador = ({titulo, detalle, valorActual = 0, valorAnterior = 0}) => {

    let obj = {

        titulo: titulo,
        detalle: detalle,
        valorActual: valorActual,
        valorAnterior: valorAnterior

    }

    return obj

}