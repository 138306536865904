/**************************************************
 * Nombre:       Seccion_Categorias
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     12 Hr
 **************************************************/
import {Grid, useMediaQuery} from "@mui/material";
import {useObtenerColleccionTabla} from "../../Servicios/BD/useObtenerColleccionTabla";
import {useEffect, useState} from "react";
import {useDialogo} from "../../Modulo_dialogos/Hooks/useDialogo";
import TituloNotificaciones from "../../Modulo_dashboard/ComponentesSecundarios/TituloNotificaciones";
import TablaBasica from "../../Modulo_tablas/Componentes/TablaBasica";
import {theme} from "../../Tema";
import Dialogo_Pqrs from "../Dialogos/Dialogo_Pqrs";
import {contarPropiedad} from "../../Servicios/BD/Contadores/contarPropiedad";

const Seccion_Pqrs = () => {
    const sCell = useMediaQuery(theme.breakpoints.only('xs'))
    const [numProductos, setNumProductos] = useState(0)
    const [recargar, setRecargar] = useState(false)
    const {props, cargarNuevamente} = useObtenerColleccionTabla({
        coleccion: 'pqrss',
        filtroInicial: 'nombre',
    })
    const {Dialogo, abrir} = useDialogo({Componente: Dialogo_Pqrs, realizado: () => setRecargar(!recargar)})


    const botones = []


    useEffect(() => {

        contarPropiedad({coleccion: 'pqrss', propiedad: 'estado', valor: 'nueva'}).then((dox) => {
            if (dox.res) {
                setNumProductos(dox.data)
            }
        })

        cargarNuevamente()

    }, [recargar]);
    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >

            <Dialogo/>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                <TituloNotificaciones titulo={'PQRS'} textoComplementario={'en nuevas'}
                                      textoResaltado={`${numProductos} Pqrs`} botones={botones}/>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 5}}>
                <TablaBasica
                    filtros={filtros}
                    datosCabecera={datosCabecera}
                    {...props}
                    click={abrir}
                    estados={['nueva', 'atendida']}
                />
            </Grid>


        </Grid>
    )

}
export default Seccion_Pqrs

const filtros = [
    {
        nombre: 'Nombre',
        propiedad: 'nombre',

    },
    {
        nombre: 'Mas Reciente',
        propiedad: 'fecha',
        direccion: 'desc'

    }
]

const datosCabecera = [
    {
        nombre: 'Fecha',
        direccion: 'left',
        propiedad: 'fecha',
        tipo: 'fecha'

    },
    {
        nombre: 'Nombre',
        direccion: 'left',
        propiedad: 'nombre',

    },
    {
        nombre: 'Tipo',
        direccion: 'left',
        propiedad: 'tipo',

    },
    {
        nombre: 'Estado',
        direccion: 'right',
        propiedad: 'estado',

    }
]


