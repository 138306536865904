/**************************************************
 * Nombre:       Formulario_Datos
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid, Typography} from "@mui/material";
import IngresoTexto from "../../Modulo_formularios/Componentes/IngresoTexto";
import {
    AccountCircle,
    PhoneAndroid,
    Badge,
    Email,
    Apartment,
    MapsHomeWork,
    House,
    Flag,
    Signpost, LocationCity, Tag, AssignmentInd
} from "@mui/icons-material";
import IngresoSelect from "../../Modulo_formularios/Componentes/IngresoSelect";
import {DEPARTAMENTOS} from "../../Modulo_envios/Constantes";
import {obtenerDepartamentos} from "../../Modulo_envios/Funciones/obtenerDepartamentos";
import {useEffect, useState} from "react";
import {obtenerCiudades} from "../../Modulo_envios/Funciones/obtenerCiudades";
import {TIPOSCALLES} from "../Constantes";

const Formulario_Datos = ({...props}) => {
    const [departamento, setDepartamento] = useState('')


    useEffect(() => {

        if (props.props.watch('departamento')) {
            setDepartamento(props.props.watch('departamento'))
        }

    }, [props.props.watch('departamento')]);
    return (
        <form noValidate style={{width: '100%'}}>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={2}
            >

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-end'}}>
                    <Typography sx={{color: '#00000080', fontSize: 14}}>Datos Personales</Typography>
                </Grid>

                <Grid item container lg={6} sm={6} xs={6} sx={{justifyContent: 'flex-start'}}>
                    <IngresoTexto Icono={AccountCircle} nombre={'Nombre'} dato={'nombre'} {...props}
                                  requerido={'El nombre es requerido*'}/>
                </Grid>

                <Grid item container lg={6} sm={6} xs={6} sx={{justifyContent: 'flex-start'}}>
                    <IngresoTexto Icono={AssignmentInd} nombre={'Apellido'} dato={'apellido'} {...props}
                                  requerido={'El nombre es requerido*'}/>
                </Grid>

                <Grid item container lg={6} sm={6} xs={6} sx={{justifyContent: 'flex-start'}}>
                    <IngresoTexto Icono={PhoneAndroid} nombre={'Celular'} dato={'celular'} {...props}
                                  requerido={'El numero de celular es requerido*'}
                    />
                </Grid>

                <Grid item container lg={6} sm={6} xs={6} sx={{justifyContent: 'flex-start'}}>
                    <IngresoTexto Icono={Badge} nombre={'Num Identificacion'} dato={'cc'} {...props}
                                  requerido={'El numero de identificacion es requerido*'}
                    />
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <IngresoTexto Icono={Email} nombre={'Correo electronico'} dato={'correo'} {...props}
                                  requerido={'El correo electronico es requerido*'}
                    />
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-end'}}>
                    <Typography sx={{color: '#00000080', fontSize: 14}}>Datos Envio</Typography>
                </Grid>

                <Grid item container lg={6} sm={6} xs={6} sx={{justifyContent: 'flex-start'}}>
                    <IngresoSelect Icono={Apartment} nombre={'Departamento'} dato={'departamento'}
                                   opciones={obtenerDepartamentos()} {...props}
                                   requerido={'El departamento a enviar es requerido*'}/>
                </Grid>

                {departamento !== '' &&
                    <Grid item container lg={6} sm={6} xs={6} sx={{justifyContent: 'flex-start'}}>
                        <IngresoSelect Icono={MapsHomeWork} nombre={'Ciudad'} dato={'ciudad'}
                                       opciones={obtenerCiudades(departamento)} {...props}
                                       requerido={'La ciudad a enviar es requerida*'}/>
                    </Grid>
                }

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <IngresoTexto Icono={LocationCity} nombre={'Nombre del barrio o sector'} dato={'barrio'} {...props}
                                  requerido={'La direccion es requerida'}
                    />
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <IngresoTexto Icono={MapsHomeWork} nombre={'Direccion'} dato={'direccion'} {...props}
                                  requerido={'La direccion es requerida'}
                    />
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <IngresoTexto Icono={Flag} nombre={'Indicaciones (Nª Casa, Nª Apartamento, Nª Oficia)'}
                                  dato={'indicaciones'} {...props} lineas={2}  />
                </Grid>




            </Grid>
        </form>
    )

}
export default Formulario_Datos