import {Dialog, Grid, IconButton, Typography, useMediaQuery} from "@mui/material";
import {useMemo, useState} from "react";
import {Close} from "@mui/icons-material";
import {theme} from "../../Tema";


export const useDialogo = ({Componente, realizado}) => {
    const sCell = useMediaQuery(theme.breakpoints.only('xs'))
    const [open, setOpen] = useState(false)
    const [datos, setDatos] = useState({})

    const abrir = (e) => {
        setDatos(e)
        setOpen(true)
    }

    const cerrar = (res) => {
        setOpen(false)
        if (res) {
            realizado()
        }

    }


    const Dialogo = () => useMemo(() => {


        return (
            <Dialog open={open} fullWidth maxWidth={'xs'} fullScreen={sCell} sx={{m: sCell ? 2 : 0}} >
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="flex-start"
                    sx={{p: sCell ? 1 : 2, overflowX: 'hidden'}}
                >

                    <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-end'}}>
                        <IconButton onClick={() => cerrar()}>
                            <Close/>
                        </IconButton>
                    </Grid>

                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="flex-start"
                        sx={{p: 1, marginTop: -2}}
                    >

                        <Componente datos={datos} cerrar={cerrar} realizado={realizado}/>

                    </Grid>


                </Grid>

            </Dialog>
        )
    }, [])

    return {
        Dialogo,
        abrir,
        cerrar,
    }
}