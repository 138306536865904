export const escogerCotizacionEconomica = (cotizaciones) => {

    cotizaciones.sort(function (a, b) {
        if (a.flete > b.flete) {
            return 1;
        }
        if (a.flete < b.flete) {
            return -1;
        }
        // a must be equal to b
        return 0;
    });

    return cotizaciones[0].idRate

}