/**************************************************
 * Nombre:       Dialogo_Producto
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Button, Grid} from "@mui/material";
import {useFormulario} from "../../Modulo_formularios/Hooks/useFormulario";
import {LoadingButton} from "@mui/lab";
import {Delete, Warning} from "@mui/icons-material";
import {guardarDoc} from "../../Servicios/BD/guardarDoc";
import {useState} from "react";
import {useLoaders} from "../../Modulo_Loaders/Hooks/useLoaders";
import logo from '../../Recursos/logo_redondo.svg'
import {borrarDoc} from "../../Servicios/BD/borrarDoc";
import Formulario_Pqrs from "../Formularios/Formulario_Pqrs";

const Dialogo_Pqrs = ({cerrar, realizado, datos}) => {
    const {props, obtenerEntidad} = useFormulario({valoresDefecto: datos})
    const [cargando, setCargando] = useState(false)
    const {Cargador, abrirCargador, cerrarCargador} = useLoaders({
        logo: logo,
    })


    const guardar = () => {

        obtenerEntidad().then((entidad) => {
            abrirCargador('Subiendo Datos')
            let obj = entidad
            guardarDoc('pqrss', obj).then((dox) => {
                if (dox.res) {
                    realizado()
                    cerrar()
                } else {
                    alert('Ups algo inesperado paso al subir el producto, por favor intentalo mas tarde')
                }
                cerrarCargador()
            })
        })
    }


    const borrar = () => {
        abrirCargador('Borrando Categoria')
        borrarDoc('pqrss', datos.id).then((dox) => {
            cerrar()
            cerrarCargador()
            realizado()
        })
    }

    return (

        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >

            <Cargador/>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                <Formulario_Pqrs props={props}/>
            </Grid>


            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center', mt: 3, mb: 1}}>
                <LoadingButton
                    loading={cargando}
                    onClick={() => guardar()}
                    startIcon={<Warning/>}
                    color={'primary'}
                    variant={'contained'}
                >
                    Actualizar Pqrs
                </LoadingButton>
            </Grid>


            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                {datos && datos.id &&
                    <Button
                        onClick={() => borrar()}
                        variant={'text'}
                        sx={{color: '#00000080', px: 0, marginTop: 2}}
                        startIcon={<Delete/>}
                    >Borrar Pqrs</Button>
                }
            </Grid>


        </Grid>

    )

}
export default Dialogo_Pqrs