/**************************************************
 * Nombre:       Formulario_Productos
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid, Typography} from "@mui/material";
import IngresoImagen from "../../Modulo_formularios/Componentes/IngresoImagen";
import IngresoTexto from "../../Modulo_formularios/Componentes/IngresoTexto";
import {
    AccountCircle, Apartment,
    ArrowRight,
    ArrowRightAlt,
    AttachMoney, BuildCircleRounded, DepartureBoard, Flag,
    Height,
    LocalMall,
    LocalMallOutlined,
    LocalOffer, Mail, PhoneAndroid, Scale,
    Upgrade
} from "@mui/icons-material";
import IngresoDinero from "../../Modulo_formularios/Componentes/IngresoDinero";
import IngresoCheckGrupo from "../../Modulo_formularios/Componentes/IngresoCheckGrupo";
import IngresoSelect from "../../Modulo_formularios/Componentes/IngresoSelect";
import {CATEGORIAS} from "../../Constantes";

const Formulario_Pqrs = ({...props}) => {

    return (
        <form noValidate style={{width: '100%'}}>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
                sx={{p: 1}}
            >

                <Grid item container lg={6} sm={6} xs={6} sx={{justifyContent: 'center'}}>
                    <IngresoTexto nombre={'Tipo'} dato={'tipo'}  {...props}
                                  editable={false}
                    />
                </Grid>

                <Grid item container lg={6} sm={6} xs={6} sx={{justifyContent: 'center'}}>
                    <IngresoSelect Icono={Flag} nombre={'Estado'} dato={'estado'}  {...props}
                                   opciones={['nueva', 'atendida']}/>
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoTexto Icono={AccountCircle} nombre={'Nombre'} dato={'nombre'}  {...props}
                                  editable={false}

                    />
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoTexto Icono={Mail} nombre={'Correo'} dato={'correo'}  {...props}
                                  editable={false}

                    />
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoTexto nombre={'Mensaje'} dato={'mensaje'} lineas={6}  {...props}
                                  editable={false}
                    />
                </Grid>




            </Grid>
        </form>

    )

}
export default Formulario_Pqrs