export const obtenerPesoTotalEnKilos = (productos) => {

    let pesoTotal = 0
    for (let i = 0; i < productos.length; i++) {
        let producto = productos[i]
        let peso = parseInt(producto.peso) * producto.cantidad
        pesoTotal = pesoTotal + peso
    }

    console.log(pesoTotal)

    pesoTotal = pesoTotal / 1000

    if (pesoTotal < 1) {
        pesoTotal = 1
    }


    return parseInt(pesoTotal.toFixed(0))

}